import { Form, Steps } from 'antd'
import { t } from 'i18next'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ModalBase, ModalContentContainer } from '../../../components'

import ProcessDeviationModalFooter from './footers/ProcessDeviationModalFooter'

import { useParams } from 'react-router-dom'
import { searchReviewSearchQueryResultItemStatus } from '../../../constants/constants'
import { useSearchReviews, useWorkspaces } from '../../../hooks'
import useProcessDeviation from '../../../hooks/useProcessDeviation'
import ProcessDeviationModalReviewStep from './steps/ProcessDeviationModalReviewStep'
import ProcessDeviationModalSetupStep from './steps/ProcessDeviationModalSetupStep'

interface ProcessDeviationModalProps {
  open: boolean
  onCancel: () => void
}

const ProcessDeviationModal = memo(
  ({ open, onCancel }: ProcessDeviationModalProps) => {
    const [form] = Form.useForm()
    const [currentStep, setCurrentStep] = useState(0)
    const [hasLoadedWorkspaces, setHasLoadedWorkspaces] = useState(false)
    const params = useParams()
    const { workspaceList, refreshWorkspaceList } = useWorkspaces({
      preventFetch: true,
    })
    const [isNextDisabled, setIsNextDisabled] = useState(false)

    const handleCancel = useCallback(() => {
      form.resetFields()
      setCurrentStep(0)
      onCancel()
    }, [form, onCancel])

    const { confirmLoading, addDeviationsToWorkspaceWithSearchReviewAndSearchQueryIds } = useProcessDeviation({
      form,
      onSuccess: handleCancel,
    })

    useEffect(() => {
      if (open && !hasLoadedWorkspaces) {
        refreshWorkspaceList()
        setHasLoadedWorkspaces(true)
      }
    }, [open, hasLoadedWorkspaces, refreshWorkspaceList])

    useEffect(() => {
      if (!open) {
        setHasLoadedWorkspaces(false)
      }
    }, [open])

    const { selectedSearchQuery, selectedSearchReview } =
      useSearchReviews(params)

    const workspaceValueWatcher = Form.useWatch('workspace', form)

    useEffect(() => {
      setIsNextDisabled(!workspaceValueWatcher)
    }, [workspaceValueWatcher])

    const includedResultsWithSummaries = useMemo(() => {
      return (
        selectedSearchQuery?.searchResults?.filter(
          (result) =>
            selectedSearchReview?.includedSearchResults?.includes(result.id) &&
            result.summaryStatus ===
              searchReviewSearchQueryResultItemStatus.READY
        ) || []
      )
    }, [
      selectedSearchQuery?.searchResults,
      selectedSearchReview?.includedSearchResults,
    ])

    const stepItems = [
      {
        title: t('processDeviationModal.steps.setup.title'),
      },
      {
        title: t('processDeviationModal.steps.review.title'),
      },
    ]

    const handleNext = useCallback(async () => {
      try {
        await form.validateFields()
        setCurrentStep(currentStep + 1)
      } catch (error) {
        console.error('Validation failed:', error)
      }
    }, [currentStep, form])

    const handleBack = () => {
      setCurrentStep(currentStep - 1)
    }

    const handleConfirm = useCallback(async () => {
      const searchReviewId = selectedSearchReview?.id
      const searchQueryId = selectedSearchQuery?.id
      if (!searchReviewId || !searchQueryId) {
        console.error('Missing searchReviewId or searchQueryId', {
          searchReviewId,
          searchQueryId,
        })
        return
      }

      await addDeviationsToWorkspaceWithSearchReviewAndSearchQueryIds(
        searchReviewId,
        searchQueryId,
        includedResultsWithSummaries
      )
    }, [
      addDeviationsToWorkspaceWithSearchReviewAndSearchQueryIds,
      includedResultsWithSummaries,
      selectedSearchQuery?.id,
      selectedSearchReview?.id,
    ])

    const steps = [
      <ProcessDeviationModalSetupStep
        form={form}
        workspaceList={workspaceList}
      />,
      <ProcessDeviationModalReviewStep
        form={form}
        selectedSearchQuery={selectedSearchQuery}
        includedResults={includedResultsWithSummaries}
      />,
    ]

    const footers = [
      <ProcessDeviationModalFooter
        onNext={handleNext}
        onCancel={handleCancel}
        disableNext={isNextDisabled}
        form={form}
      />,
      <ProcessDeviationModalFooter
        onSubmit={handleConfirm}
        onPrevious={handleBack}
        onCancel={handleCancel}
        form={form}
      />,
    ]

    return (
      <ModalBase
        title={t('processDeviationModal.title')}
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={footers[currentStep]}
      >
        <Steps size="small" current={currentStep} items={stepItems} />
        <Form form={form} layout="vertical">
          <ModalContentContainer>{steps[currentStep]}</ModalContentContainer>
        </Form>
      </ModalBase>
    )
  }
)

export default ProcessDeviationModal
