import { algorithmRunStatuses } from '../../constants/constants'
import { WorkspaceResponse } from '../../hooks/useWorkspaces'
import { AlgorithmRun, Workspace } from '../../store/reducers/workspaceReducer'

const algorithmRunsArray: AlgorithmRun[] = [
  {
    id: '1',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.ONGOING_AI,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '2',
    workspace: undefined,
    dataSource: undefined,
    runStatus: 'COMPLETE',
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '3',
    workspace: undefined,
    dataSource: undefined,
    runStatus: 'FAILED',
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '4',
    workspace: undefined,
    dataSource: undefined,
    runStatus: 'READY',
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '5',
    workspace: undefined,
    dataSource: undefined,
    runStatus: 'CANCELLED',
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
]

const workspaceMock: WorkspaceResponse = {
  data: [
    {
      id: '51748',
      clusterId: null,
      externalId: null,
      title: 'RefAI workspace',
      description: 'RefAI workspace',
      workspaceType: '45064',
      dataSource: '51744',
      algorithmRuns: algorithmRunsArray,
      createdBy: '63f79442930987a1621ef75a',
      lastModifiedBy: '63f79442930987a1621ef75a',
      createdDate: '2023-10-05T15:42:41.893Z',
      lastModifiedDate: '2023-10-05T15:42:41.893Z',
      participants: [],
      visibility: 'ORGANIZATION',
      typeName: 'Assets Merger & Acquisition',
      typeCode: 'REFAI',
    },
    {
      id: '50165',
      clusterId: null,
      externalId: null,
      title: 'test',
      description: 'test',
      workspaceType: '45064',
      dataSource: '49465',
      algorithmRuns: algorithmRunsArray,
      createdBy: '63d9602a68abf834dee77a07',
      lastModifiedBy: '63d9602a68abf834dee77a07',
      createdDate: '2023-09-28T16:40:53.323Z',
      lastModifiedDate: '2023-10-04T15:10:17.155Z',
      participants: ['63d9602a68abf834dee77a07', '63f79442930987a1621ef75a'],
      visibility: 'PRIVATE',
      typeName: 'Assets Merger & Acquisition',
      typeCode: 'REFAI',
    },
    {
      id: '50166',
      clusterId: null,
      externalId: null,
      title: 'Test workspace',
      description: 't',
      workspaceType: '45064',
      dataSource: '49465',
      algorithmRuns: [],
      createdBy: '6447fab3a4cf88f35a308625',
      lastModifiedBy: '63d9602a68abf834dee77a07',
      createdDate: '2023-09-29T19:25:16.404Z',
      lastModifiedDate: '2023-10-04T15:02:44.855Z',
      participants: ['63d9602a68abf834dee77a07'],
      visibility: 'PRIVATE',
      typeName: 'Assets Merger & Acquisition',
      typeCode: 'REFAI',
    },
  ],
  totalResults: 3,
  totalPages: 1,
  page: 1,
  pageSize: 100,
}

export default workspaceMock

export const workspaceListMock: Workspace[] = [
  {
    id: '1',
    title: 'RiskRelief Workspace 1',
    typeCode: 'RISK_REL',
    description: 'RiskRelief Workspace 1',
    createdDate: new Date().toISOString(),
    lastModifiedDate: new Date().toISOString(),
    visibility: 'PRIVATE',
    typeName: 'RiskRelief',
    dataSource: '1',
    algorithmRuns: [],
  },
]
