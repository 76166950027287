import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { SimpleSwitch } from '../../../components'
import { riskReliefDeviationStatuses } from '../../../constants/constants'
import { useWorkspaceDetails } from '../../../hooks'
import notificationService from '../../../services/notificationService'
import workspaceService from '../../../services/workspaceService'
import {
  Deviation,
  WorkspaceConfig,
} from '../../../store/reducers/workspaceReducer'

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
`

const DeviationStatusSwitch = () => {
  const { workspaceId, deviationId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [loading, setLoading] = useState(false)

  const selectedDeviation = useMemo(
    () =>
      selectedWorkspace?.config?.riskReliefDeviations?.find(
        (deviation) => deviation.id === deviationId
      ),
    [selectedWorkspace, deviationId]
  )

  const handleOnClick = useCallback(() => {
    setLoading(true)
    if (selectedDeviation) {
      const newDeviation: Deviation = {
        ...selectedDeviation,
        deviationStatus:
          selectedDeviation?.deviationStatus ===
          riskReliefDeviationStatuses.CLOSED
            ? riskReliefDeviationStatuses.OPENED
            : riskReliefDeviationStatuses.CLOSED,
      }

      const newDeviations = selectedWorkspace?.config?.riskReliefDeviations
        ?.filter((deviation) => deviation.id !== deviationId)
        .concat(newDeviation)
      const config: WorkspaceConfig = {
        ...selectedWorkspace?.config,
        riskReliefDeviations: newDeviations,
      }
      workspaceService
        .updateWorkspaceDetails({
          workspaceId: workspaceId ?? '',
          config,
        })
        .then(() => {
          notificationService.notificationSuccess(
            t('workspaceDetailsPage.updateWorkspaceSuccessful') || ''
          )
          setLoading(false)
          refreshWorkspaceDetails()
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          setLoading(false)
        })
    }
  }, [
    refreshWorkspaceDetails,
    workspaceId,
    setLoading,
    selectedDeviation,
    deviationId,
    selectedWorkspace,
  ])

  return (
    <SwitchContainer>
      {selectedDeviation?.deviationStatus === riskReliefDeviationStatuses.CLOSED
        ? t('workspaceDetailsPage.deviationStatus.closed')
        : t('workspaceDetailsPage.deviationStatus.opened')}
      <SimpleSwitch
        checked={
          !(
            selectedDeviation?.deviationStatus ===
            riskReliefDeviationStatuses.CLOSED
          )
        }
        onClick={handleOnClick}
        loading={loading}
      />
    </SwitchContainer>
  )
}

export default DeviationStatusSwitch
