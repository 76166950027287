import { Form, FormInstance, Select } from 'antd'
import { t } from 'i18next'
import { memo, useMemo } from 'react'
import { workspaceTypes } from '../../../../constants/constants'
import { Workspace } from '../../../../store/reducers/workspaceReducer'

interface ProcessDeviationModalSetupStepProps {
  form: FormInstance
  workspaceList?: Workspace[]
}

const ProcessDeviationModalSetupStep = memo(
  ({ form, workspaceList }: ProcessDeviationModalSetupStepProps) => {
    const riskReliefWorkspaces = useMemo(() => {
      return (
        workspaceList
          ?.filter(
            (workspace) => workspace.typeCode === workspaceTypes.RISK_REL
          )
          .map((workspace) => ({
            label: workspace.title,
            value: workspace.id,
          })) || []
      )
    }, [workspaceList])

    return (
      <Form.Item
        name="workspace"
        label={t('processDeviationModal.steps.setup.formTitle')}
        extra={t('processDeviationModal.steps.setup.formExtra')}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <Select
          placeholder={t('processDeviationModal.steps.setup.selectPlaceholder')}
          options={riskReliefWorkspaces}
          loading={!workspaceList}
          notFoundContent={t(
            'processDeviationModal.steps.setup.noWorkspacesFound'
          )}
          onChange={(value, option) => {
            form.setFieldValue('workspace', option)
          }}
        />
      </Form.Item>
    )
  }
)

export default ProcessDeviationModalSetupStep