import { Button, FormInstance } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

interface ProcessDeviationModalFooterProps {
  onNext?: () => void
  onPrevious?: () => void
  onSubmit?: () => void
  onCancel: () => void
  form: FormInstance
  disableNext?: boolean
}

const ProcessDeviationModalFooter = memo(
  ({
    onNext,
    onPrevious,
    onSubmit,
    onCancel,
    disableNext,
  }: ProcessDeviationModalFooterProps) => {
    return (
      <FooterContainer>
        <Button onClick={onCancel}>
          {t('processDeviationModal.footer.cancelButton')}
        </Button>
        {onPrevious && (
          <Button onClick={onPrevious}>
            {t('processDeviationModal.footer.backButton')}
          </Button>
        )}
        {onNext && (
          <Button
            type="primary"
            onClick={onNext}
            disabled={disableNext}
          >
            {t('processDeviationModal.footer.nextButton')}
          </Button>
        )}
        {onSubmit && (
          <Button type="primary" onClick={onSubmit}>
            {t('processDeviationModal.footer.submitButton')}
          </Button>
        )}
      </FooterContainer>
    )
  }
)

export default ProcessDeviationModalFooter
