import { theme } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout'
import { ReactNode, memo, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MainHeader, MainMenu } from '../components'

export interface Path {
  DEFAULT: string
  FORGOT_PASSWORD: string
  LOGIN: string
  LOGIN_SSO: string
  LOGIN_UNAUTHORIZED: string
  SEARCH: string
  SEARCH_DOCUMENT_DETAILS: string
  SEARCH_DOCUMENT_SUMMARIES: string
  SEARCH_REVIEW_LIST: string
  SEARCH_REVIEW_DETAILS: string
  SEARCH_REVIEW_SEARCH: string
  SEARCH_REVIEW_DOCUMENT: string
  USER_LIST: string
  USER_DETAILS: string
  DATA_SOURCE_LIST: string
  DATA_SOURCE_DETAILS: string
  DATA_STORE_LIST: string
  DATA_STORE_DETAILS: string
  WORKSPACE_LIST: string
  WORKSPACE_DETAILS: string
  WORKSPACE_SMART_FUNCTION_DETAILS_REFAI: string
  WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK: string
  WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB: string
  WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR: string
  WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS: string
  WORKSPACE_SMART_FUNCTION_DETAILS_DEL: string
  WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION: string
  WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY: string
  WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION: string
  SINGLE_WORKSPACE_DETAILS: string
  SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI: string
  AUDIT_TRAIL_LIST: string
  SETTINGS: string
  OFR_DOCUMENT_TEMPLATE_LIST: string
  OFR_DOCUMENT_TEMPLATE_DETAILS: string
}

export const routePaths: Path = {
  DEFAULT: '/',
  FORGOT_PASSWORD: '/forgotPassword',
  LOGIN: '/login',
  LOGIN_SSO: '/loginSso',
  LOGIN_UNAUTHORIZED: '/login/displayUnauthorizeNotification',
  SEARCH: '/search',
  SEARCH_DOCUMENT_DETAILS: '/searchDocumentDetails',
  SEARCH_DOCUMENT_SUMMARIES: '/searchDocumentSummaries',
  SEARCH_REVIEW_LIST: '/searchReviewList',
  SEARCH_REVIEW_DETAILS: '/searchReviewDetails',
  SEARCH_REVIEW_SEARCH: '/searchReviewSearch',
  SEARCH_REVIEW_DOCUMENT: '/searchReviewDocument',
  USER_LIST: '/userList',
  USER_DETAILS: '/userDetails',
  DATA_SOURCE_LIST: '/dataSourceList',
  DATA_SOURCE_DETAILS: '/dataSourceDetails',
  DATA_STORE_LIST: '/dataStoreList',
  DATA_STORE_DETAILS: '/dataStoreDetails',
  WORKSPACE_LIST: '/workspaceList',
  WORKSPACE_DETAILS: '/workspaceDetails',
  WORKSPACE_SMART_FUNCTION_DETAILS_REFAI:
    '/workspaceSmartFunctionDetails_REFAI',
  WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK:
    '/workspaceSmartFunctionDetails_BIOMARK',
  WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB:
    '/workspaceSmartFunctionDetails_REGSUB',
  WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR:
    '/workspaceSmartFunctionDetails_OFR_QR',
  WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS:
    '/workspaceSmartFunctionDetails_OFR_QR/documentDetails',
  WORKSPACE_SMART_FUNCTION_DETAILS_DEL: '/workspaceSmartFunctionDetails_DEL',
  WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION:
    '/workspaceSmartFunctionDetails_RISK_REL_DEVIATION_CLASSIFICATION',
  WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY:
    '/workspaceSmartFunctionDetails_RISK_REL_ROOT_CAUSE_DISCOVERY',
  WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION:
    '/workspaceSmartFunctionDetails_RISK_REL_CAPA_GENERATION',
  SINGLE_WORKSPACE_DETAILS: '/singleWorkspaceDetails',
  SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI:
    '/singleWorkspaceSmartFunctionDetails_REFAI',
  AUDIT_TRAIL_LIST: '/auditTrailList',
  SETTINGS: '/settings',
  OFR_DOCUMENT_TEMPLATE_LIST: '/ofrDocumentTemplateList',
  OFR_DOCUMENT_TEMPLATE_DETAILS: '/ofrDocumentTemplateDetails',
}

const mainMenuPaths = [
  routePaths.SEARCH,
  routePaths.SEARCH_DOCUMENT_DETAILS,
  routePaths.SEARCH_DOCUMENT_SUMMARIES,
  routePaths.SEARCH_REVIEW_LIST,
  routePaths.SEARCH_REVIEW_DETAILS,
  routePaths.SEARCH_REVIEW_SEARCH,
  routePaths.SEARCH_REVIEW_DOCUMENT,
  routePaths.USER_LIST,
  routePaths.USER_DETAILS,
  routePaths.DATA_SOURCE_LIST,
  routePaths.DATA_SOURCE_DETAILS,
  routePaths.DATA_STORE_LIST,
  routePaths.DATA_STORE_DETAILS,
  routePaths.WORKSPACE_LIST,
  routePaths.WORKSPACE_DETAILS,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION,
  routePaths.AUDIT_TRAIL_LIST,
  routePaths.SETTINGS,
  routePaths.OFR_DOCUMENT_TEMPLATE_LIST,
  routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS,
]

const mainHeaderPaths = [
  routePaths.SEARCH,
  routePaths.SEARCH_DOCUMENT_DETAILS,
  routePaths.SEARCH_DOCUMENT_SUMMARIES,
  routePaths.SEARCH_REVIEW_LIST,
  routePaths.SEARCH_REVIEW_DETAILS,
  routePaths.SEARCH_REVIEW_SEARCH,
  routePaths.SEARCH_REVIEW_DOCUMENT,
  routePaths.USER_LIST,
  routePaths.USER_DETAILS,
  routePaths.DATA_SOURCE_LIST,
  routePaths.DATA_SOURCE_DETAILS,
  routePaths.DATA_STORE_LIST,
  routePaths.DATA_STORE_DETAILS,
  routePaths.WORKSPACE_LIST,
  routePaths.WORKSPACE_DETAILS,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY,
  routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION,
  routePaths.AUDIT_TRAIL_LIST,
  routePaths.SETTINGS,
  routePaths.OFR_DOCUMENT_TEMPLATE_LIST,
  routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS,
]

interface RootPageProps {
  children?: ReactNode
}

const { useToken } = theme

const RootPage = memo(({ children }: RootPageProps) => {
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(true)
  const { token } = useToken()

  const isPathAllowed = useCallback(
    (pathList: string[]) => {
      const basePath = location.pathname.split('/')[1]
      return pathList.includes(`/${basePath}`)
    },
    [location.pathname]
  )

  const isInIframe = useMemo(() => window.self !== window.top, [])

  const getMenu = useCallback(() => {
    if (isPathAllowed(mainMenuPaths) && !isInIframe) {
      return <MainMenu collapsed={collapsed} setCollapsed={setCollapsed} />
    }
  }, [isPathAllowed, setCollapsed, collapsed, isInIframe])

  const getHeader = useCallback(() => {
    if (isPathAllowed(mainHeaderPaths) && !isInIframe) {
      return <MainHeader mainMenuCollapsed={collapsed} />
    }
    return ''
  }, [isPathAllowed, collapsed, isInIframe])

  const menuBorder = useMemo(() => {
    const showMenuBorder = isPathAllowed(mainMenuPaths)
    const menuBorderStyle = {
      borderLeft: '1px solid var(--color-border, rgba(6, 5, 5, 0.15))',
    }
    return {
      ...(showMenuBorder && menuBorderStyle),
    }
  }, [isPathAllowed])

  const rootPageBackgroundColor = useMemo(() => {
    return isPathAllowed(mainHeaderPaths) && !isInIframe
      ? token.colorBgContainer
      : token.colorBgLayout
  }, [isPathAllowed, isInIframe, token])

  return (
    <Layout
      style={{
        display: 'flex',
        backgroundColor: token.colorBgLayout,
      }}
    >
      {getMenu()}

      <Layout
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          backgroundColor: rootPageBackgroundColor,
        }}
      >
        {getHeader()}
        <Content
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: isInIframe ? 0 : 64,
            marginLeft:
              isPathAllowed(mainMenuPaths) && !isInIframe
                ? collapsed
                  ? 80
                  : 200
                : 0,
            transitionDelay: '0s, 0s',
            transitionDuration: '0.2s, 0s',
            transitionProperty: 'all, background',
            transitionTimingFunction: 'ease, ease',
            overflow: 'auto',
            maxHeight: isInIframe ? '100vh' : 'calc(100vh - 64px)',
            ...menuBorder,
            backgroundColor: rootPageBackgroundColor,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
})

export default RootPage
