import { Select, theme } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { SelectOption } from '../../../types/generalTypes'

const { useToken } = theme

export type DeviationStatus = 'all' | 'opened' | 'closed'

interface SelectDeviationStatusProps {
  defaultValue?: DeviationStatus
  value?: DeviationStatus
  onChange?: (value: DeviationStatus | undefined) => void
  style?: Object
  loading?: boolean
}

const SelectDeviationStatus = ({
  defaultValue,
  value,
  onChange,
  loading,
}: SelectDeviationStatusProps) => {
  const { token } = useToken()
  const [selectedValue, setSelectedValue] = useState<
    DeviationStatus | undefined
  >(defaultValue)

  const options: SelectOption[] = [
    {
      label: t('workspaceDetailsPage.selectDeviationStatus.all') || '',
      value: 'all',
    },
    {
      label: t('workspaceDetailsPage.selectDeviationStatus.opened') || '',
      value: 'opened',
    },
    {
      label: t('workspaceDetailsPage.selectDeviationStatus.closed') || '',
      value: 'closed',
    },
  ]

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  const handleChange = (value: DeviationStatus) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      style={{ minWidth: 143, color: token.colorPrimary }}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('workspaceDetailsPage.selectDeviationStatus.all')}
      loading={loading}
    />
  )
}

export default SelectDeviationStatus
