import { Tag } from 'antd'
import { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { BadgeColors } from '../../constants/constants'
import { RootState } from '../../store'
import { badgeColors } from '../../theme'

interface BadgeColorMap {
  [key: string]: string
}

const badgeColorMap: BadgeColorMap = {
  [BadgeColors.DEFAULT]: 'default',
  [BadgeColors.ERROR]: 'error',
  [BadgeColors.SUCCESS]: 'success',
  [BadgeColors.PROCESSING]: 'processing',
  [BadgeColors.WARNING]: 'warning',
  [BadgeColors.DATA_SOURCE]: 'dataSource',
  [BadgeColors.ROOT_CAUSE_DISCOVERY]: 'rootCauseDiscovery',
  [BadgeColors.FILTERS]: 'filters',
  [BadgeColors.SEARCH_REVIEWS]: 'searchReviews',
  [BadgeColors.TEMPLATE_ANNOTATIONS]: 'templateAnnotations',
  [BadgeColors.STATUS_OPENED]: 'statusOpened',
  [BadgeColors.STATUS_CLOSED]: 'statusClosed',
} as const

export interface BadgeProps {
  color?: BadgeColors | string
  icon?: ReactNode
  children?: ReactNode
}

const Badge = ({
  color = BadgeColors.DEFAULT,
  icon = null,
  children = null,
}: BadgeProps) => {
  const selectedTheme = useSelector(
    (state: RootState) => state.theme.selectedTheme
  )

  const colorMapping = useMemo(() => {
    if (!color) {
      return badgeColors[selectedTheme].default
    }

    const isValidBadgeColor = Object.values(BadgeColors).includes(
      color as BadgeColors
    )

    if (!isValidBadgeColor) {
      return badgeColors[selectedTheme].default
    }

    const colorKey = badgeColorMap[color]
    return badgeColors[selectedTheme][colorKey]
  }, [color, selectedTheme])

  return (
    <Tag
      icon={icon}
      style={{
        backgroundColor: colorMapping.background,
        border: `1px solid ${colorMapping.border}`,
        color: colorMapping.text,
      }}
    >
      {children}
    </Tag>
  )
}

export default Badge
