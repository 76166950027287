import { DatabaseOutlined, FilterOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  ListPageLayout,
  PageContentDropdown,
  Spacer,
  SubHeader,
} from '../../components'
import Badge from '../../components/display/Badge'
import HistoricalReviewSearchTable from '../../components/HistoricalReviewSearchTable'
import { SearchTableRow } from '../../components/SearchTable'
import {
  BadgeColors,
  dateFormat,
  searchReviewSearchQueryResultItemStatus,
} from '../../constants/constants'
import { useSearchReviews } from '../../hooks'
import { routePaths } from '../RootPage'
import {
  searchReviewPanelTypes,
  searchReviewTabs,
} from '../SearchReviewDetailsPage/SearchReviewDetailsPage'
import ProcessDeviationModal from './modals/ProcessDeviationModal'
import {
  BadgesContainer,
  FloatLeftContainer,
} from './SearchReviewSearchQueryDocumentListPage'
const QueryTextTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  flex-grow: 1;
  word-wrap: break-word;
`

const SearchReviewSearchQueryHistoricalReviewListPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [loadingIds, setLoadingIds] = useState<string[]>([])
  const {
    selectedSearchQuery,
    selectedSearchReview,
    exportSearchReviewSearchQueryCsv,
    summarizeSearchReviewSearchQueryResult,
    refreshSearchReviewList,
    saveSearchQueryInclusion
  } = useSearchReviews(params)

  const [openProcessDeviationModal, setOpenProcessDeviationModal] =
    useState(false)

  const handleOpenProcessDeviationModal = () => {
    setOpenProcessDeviationModal(true)
  }

  const handleCloseProcessDeviationModal = () => {
    setOpenProcessDeviationModal(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadingIds.length === 0) refreshSearchReviewList()
    }, 30000)
    return () => clearInterval(interval)
  }, [loadingIds, refreshSearchReviewList])

  const formattedData: SearchTableRow[] = useMemo(
    () =>
      selectedSearchQuery?.searchResults
        ? selectedSearchQuery?.searchResults.map((document, index) => {
            return {
              ...document,
              key: index.toString(),
              id: document?.id || '',
              title: document?.title,
              publicationName: document?.publicationName || '',
              authors: document?.authors,
              publicationDate: document?.publicationDate || '',
              included: selectedSearchReview?.includedSearchResults?.includes(
                document?.id || ''
              ),
              documentLink: document?.documentLink,
              language: document.language || '',
              summaryStatus: document?.summaryStatus || '',
            }
          })
        : [],
    [selectedSearchQuery, selectedSearchReview]
  )


  const sourceText = useMemo(
    () => selectedSearchQuery?.searchSource.title,
    [selectedSearchQuery?.searchSource.title]
  )

  const handleSummarize = (resultId: string) => {
    if (!selectedSearchReview && !resultId) return
    summarizeSearchReviewSearchQueryResult({
      searchReviewId: selectedSearchReview?.id || '',
      resultId,
    })
  }

  const appendLoadingId = (recordId: string) => {
    const allLoadingIds = [...loadingIds, recordId]
    setLoadingIds(allLoadingIds)
  }

  const removeLoadingId = (recordId: string) => {
    const allLoadingIds = loadingIds.filter((id) => id !== recordId)
    setLoadingIds(allLoadingIds)
  }
  const handleOnSwitchClick = async (recordId: string) => {
    // append to loadingIds
    appendLoadingId(recordId)

    const handleResponse = () => {
      removeLoadingId(recordId)
    }

    try {
      await saveSearchQueryInclusion({
        recordId,
        navigate,
      })
      handleResponse()
    } catch (error) {
      console.error('axios fetch error', error)
      handleResponse()
    }
  }

  const handleExport = () => {
    exportSearchReviewSearchQueryCsv(
      selectedSearchReview?.id || '',
      selectedSearchQuery?.searchEvent || ''
    )
  }

  const handleOnRowClick = (recordId: string) => {
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedSearchReview?.id}/${params.searchId}/${recordId}`
    )
  }

  const deviationsData = useMemo(() => {
    if (!selectedSearchQuery?.searchResults?.length) return {}
    const firstDocId = selectedSearchQuery.searchResults[0]?.id

    return {
      [firstDocId]: [
        {
          text: 'First deviation for this record',
          badges: ['Critical', 'High Priority'],
          rootCause: 'Equipment malfunction in processing unit A-123',
          deviationSummary:
            'Temperature exceeded normal operating parameters during batch processing',
          capaSummary:
            'Implemented enhanced monitoring system and preventive maintenance schedule',
        },
        {
          text: 'Second deviation with different badges',
          badges: ['Medium Priority'],
          rootCause: 'Operator error during setup procedure',
          deviationSummary: 'Incorrect configuration of control parameters',
          capaSummary: 'Updated SOP and provided additional operator training',
        },
      ],
    }
  }, [selectedSearchQuery?.searchResults])

  const disableProcessDeviationButton = useMemo(() => {
    const summaryExists = selectedSearchQuery?.searchResults?.some(
      (doc) =>
        doc.summaryStatus === searchReviewSearchQueryResultItemStatus.READY
    )
    return (
      !summaryExists || !selectedSearchReview?.includedSearchResults?.length
    )
  }, [
    selectedSearchQuery?.searchResults,
    selectedSearchReview?.includedSearchResults?.length,
  ])

  return (
    <ListPageLayout>
      <SubHeader>
        <BackButton
          tooltipMessage={
            t('searchReviewSearchDetailsPage.backButtonTooltipMessage') || ''
          }
          url={`${routePaths.SEARCH_REVIEW_DETAILS}/${selectedSearchReview?.id}/${searchReviewPanelTypes.OVERVIEW}/${searchReviewTabs.QUERIES}`}
        />
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <QueryTextTitle>{selectedSearchQuery?.searchText}</QueryTextTitle>
          <Spacer />
          <FloatLeftContainer style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div>
              <Moment
                date={selectedSearchQuery?.searchDate}
                format={dateFormat.PRIMARY}
              />
            </div>
            <BadgesContainer>
              {sourceText && (
                <Badge
                  color={BadgeColors.DATA_SOURCE}
                  icon={<DatabaseOutlined />}
                >
                  {sourceText}
                </Badge>
              )}
              {selectedSearchQuery?.searchParameters.date && (
                <Badge color={BadgeColors.FILTERS} icon={<FilterOutlined />}>
                  {selectedSearchQuery?.searchParameters.date}
                </Badge>
              )}
            </BadgesContainer>
            <div>
              {t('Result total')}: {selectedSearchQuery?.searchResults.length}
            </div>
            <>
              <Button
                disabled={disableProcessDeviationButton}
                onClick={handleOpenProcessDeviationModal}
                type="primary"
              >
                {t('searchReviewSearchDetailsPage.processDeviationsButtonText')}
              </Button>
              <ProcessDeviationModal
                open={openProcessDeviationModal}
                onCancel={handleCloseProcessDeviationModal}
              />
            </>

            <PageContentDropdown onExport={handleExport} />
          </FloatLeftContainer>
        </div>
      </SubHeader>
      <HistoricalReviewSearchTable
        formattedData={formattedData}
        loadingIds={loadingIds}
        onClick={handleOnRowClick}
        maxHeight="calc(100vh - 180px)"
        onSummarize={handleSummarize}
        deviations={deviationsData}
        onSwitchClick={handleOnSwitchClick}
      />
    </ListPageLayout>
  )
}
export default SearchReviewSearchQueryHistoricalReviewListPage
