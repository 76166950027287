import component from './component-en.json'
import errorMessage from './errorMessage-en.json'
import general from './general-en.json'
import biomarkConfigurationModal from './modals/biomarkConfigurationModal-en.json'
import createDataSourceModal from './modals/createDataSourceModal-en.json'
import createDataStoreModal from './modals/createDataStoreModal-en.json'
import createOfrDocumentTemplateModal from './modals/createOfrDocumentTemplateModal-en.json'
import createSearchReviewModal from './modals/createSearchReviewModal-en.json'
import createWorkspaceModal from './modals/createWorkspaceModal-en.json'
import delConfigurationModal from './modals/delConfigurationModal-en.json'
import regulatorySubmissionConfigurationModal from './modals/regulatorySubmissionConfigurationModal-en.json'
import auditTrailListPage from './pages/auditTrailListPage-en.json'
import dataSourceDetailsPage from './pages/dataSourceDetailsPage-en.json'
import dataSourcesListPage from './pages/dataSourcesListPage-en.json'
import dataStoreDetailsPage from './pages/dataStoreDetailsPage-en.json'
import dataStoresListPage from './pages/dataStoresListPage-en.json'
import forgotPasswordPage from './pages/forgotPasswordPage-en.json'
import inviteCompleteProfile from './pages/inviteCompleteProfile-en.json'
import loginPage from './pages/loginPage-en.json'
import ofrDocumentTemplateDetailsPage from './pages/ofrDocumentTemplateDetailsPage-en.json'
import ofrDocumentTemplateListPage from './pages/ofrDocumentTemplateListPage-en.json'
import searchDocumentSummariesPage from './pages/searchDocumentSummariesPage-en.json'
import searchPage from './pages/searchPage-en.json'
import searchReviewDetailsPage from './pages/searchReviewDetailsPage-en.json'
import searchReviewListPage from './pages/searchReviewListPage-en.json'
import searchReviewSearchDetailsPage from './pages/searchReviewSearchDetailsPage-en.json'
import searchReviewSearchDocumentDetailsPage from './pages/searchReviewSearchDocumentDetailsPage-en.json'
import settingsPage from './pages/settingsPage-en.json'
import smartFunctionDetailsPage from './pages/smartFunctionDetailsPage-en.json'
import userDetailsPage from './pages/userDetailsPage-en.json'
import userListPage from './pages/userListPage-en.json'
import workspaceDetailsPage from './pages/workspaceDetailsPage-en.json'
import workspaceListPage from './pages/workspaceListPage-en.json'
import processDeviationModal from './modals/processDeviationModal-en.json'

const translationsEN = {
  ...component,
  ...general,
  auditTrailListPage,
  biomarkConfigurationModal,
  createDataSourceModal,
  createDataStoreModal,
  createOfrDocumentTemplateModal,
  createSearchReviewModal,
  createWorkspaceModal,
  dataSourceDetailsPage,
  dataSourcesListPage,
  dataStoreDetailsPage,
  dataStoresListPage,
  delConfigurationModal,
  errorMessage,
  forgotPasswordPage,
  inviteCompleteProfile,
  loginPage,
  ofrDocumentTemplateDetailsPage,
  ofrDocumentTemplateListPage,
  regulatorySubmissionConfigurationModal,
  searchDocumentSummariesPage,
  searchPage,
  searchReviewDetailsPage,
  searchReviewListPage,
  searchReviewSearchDetailsPage,
  searchReviewSearchDocumentDetailsPage,
  settingsPage,
  smartFunctionDetailsPage,
  userDetailsPage,
  userListPage,
  workspaceDetailsPage,
  workspaceListPage,
  processDeviationModal
}

export default translationsEN
