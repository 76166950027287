import { ReactNode } from 'react'
import styled from 'styled-components'

const SectionContainer = styled.div`
  margin: 8px 0;
`

const SectionLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
`
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: ${(props) => props.theme.token.colorBgContainer};
`

export interface InfoSectionProps {
  label?: string | ReactNode
  children?: ReactNode
}

const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <SectionContainer>
      <SectionLabel>{label}</SectionLabel>
      <SectionContent>{children}</SectionContent>
    </SectionContainer>
  )
}
export default InfoSection
