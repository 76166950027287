import { Form, Radio, RadioChangeEvent, Space } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectParticipant } from '../../../../components'
import { privacyTypes } from '../../../../constants/constants'
import { RootState } from '../../../../store'
import { DataSource } from '../../../../store/reducers/dataSourceReducer'

interface CreateWorkspaceModalPrivacyStepProps {
  form: FormInstance
  dataSource?: DataSource
}

const CreateWorkspaceModalPrivacyStep = memo(
  ({ form, dataSource }: CreateWorkspaceModalPrivacyStepProps) => {
    const [isSelectDisabled, setIsSelectDisabled] = useState(
      form.getFieldValue('visibility') !== privacyTypes.PRIVATE
    )

    const currentUser = useSelector((state: RootState) => state.user.currentUser)

    const handleRadioChange = useCallback(
      async (e: RadioChangeEvent) => {
        const isPrivate = e.target.value === privacyTypes.PRIVATE
        const participants = isPrivate && currentUser ? [currentUser.id] : []
        setIsSelectDisabled(!isPrivate)
        form.setFieldsValue({ participants })
        if (isPrivate) {
          try {
            await form.validateFields(['participants'])
          } catch (error) {
          }
        }
      },
      [currentUser, form]
    )

    return (
      <>
        <Form.Item
          label={t('createWorkspaceModal.privacyStep.subHeader')}
          name="visibility"
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
        >
          <Radio.Group buttonStyle="solid" onChange={handleRadioChange}>
            <Space direction="vertical">
              <Radio value={privacyTypes.PRIVATE}>
                {t('createWorkspaceModal.privacyStep.privateOption')}
              </Radio>
              <Radio
                value={privacyTypes.ORGANIZATION}
                disabled={dataSource?.visibility === privacyTypes.PRIVATE}
              >
                {t('createWorkspaceModal.privacyStep.organizationalOption')}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('createWorkspaceModal.privacyStep.participantSelectHeader')}
          name="participants"
          rules={[
            {
              required: !isSelectDisabled,
              message: t('errorMessage.isRequired') || '',
            },
            {
              validator: (_, value) => {
                if (!isSelectDisabled && (!value || value.length === 0)) {
                  return Promise.reject(t('errorMessage.isRequired') || '')
                }
                return Promise.resolve()
              },
            },
          ]}
          extra={t('createWorkspaceModal.privacyStep.extraInputPlaceholder') || ''}
        >
          <SelectParticipant
            disabled={isSelectDisabled}
            initialParticipants={form.getFieldValue('participants')}
            allParticipants={dataSource?.participants}
          />
        </Form.Item>
      </>
    )
  }
)

export default CreateWorkspaceModalPrivacyStep
