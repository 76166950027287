import {
  PUBMED_SOURCE_TYPE,
  searchReviewTypes,
} from '../../constants/constants'
import { SearchReview } from '../../store/reducers/searchReviewReducer'

export const searchSourceMock = {
  id: '27456',
  clusterId: null,
  externalId: null,
  oid: '6410a0ae33dca06ec653b226',
  sourceType: PUBMED_SOURCE_TYPE,
  title: 'Protein',
  description: null,
  visibility: 'PUBLIC',
  organizationName: 'GenAIz Dev',
  contactName: null,
  contactEmail: null,
  defaultSource: false,
  config: {
    authorsKey: 'authors',
    databaseName: 'protein',
    authorNameKey: 'name',
    documentIdKey: 'caption',
    documentLinkBase: 'https://www.ncbi.nlm.nih.gov/protein/',
    documentTitleKey: 'title',
    publicationDateKey: 'createdate',
    publicationNameKey: 'source_db',
  },
  participants: [],
  createdBy: '63f79442930987a1621ef75a',
  lastModifiedBy: '63f79442930987a1621ef75a',
  createdDate: '2023-07-17T19:39:52.012Z',
  lastModifiedDate: '2023-07-17T19:39:52.012Z',
  typeName: 'National Library of Medicine',
  typeCode: 'NLM',
  isInternal: false,
}

const literatureReviewMock: SearchReview[] = [
  {
    id: '29169',
    oid: null,
    title: 'Mock Proteins',
    description: null,
    reviewType: searchReviewTypes.LITERATURE_REVIEW,
    searchQueries: [
      {
        id: '29270',
        searchText: 'Mock test',
        searchDescription: 'Mock test',
        searchParameters: {
          date: 'All',
          page: 1,
          source: 27456,
          dataType: ['Document'],
          language: 'en',
          pageSize: 100,
          searchQuery: 'test',
          searchMethod: 'KEYWORD_SEARCH_METHOD',
        },
        searchSource: searchSourceMock,
        searchDate: '2023-07-24T15:34:33.351Z',
        searchActionBy: {
          id: 'user1',
          createdBy: 'AdminUser',
          displayName: 'User 1',
          email: 'user1@example.com',
          isActive: true,
          language: 'en',
          lastModifiedBy: 'AdminUser',
          role: '',
        },
        searchResults: [
          {
            id: '29171',
            oid: null,
            urn: '30226337',
            title:
              'Mock A review of the non-equivalent control group post-test-only design.',
            publicationName: null,
            language: null,
            documentLink: 'https://www.ncbi.nlm.nih.gov/protein/30226337',
            authors: ['Krishnan P'],
            publicationDate: null,
            literatureReview: '29169',
            searchEvent: '29270',
            csv: '',
          },
          {
            id: '29170',
            oid: null,
            urn: '32155099',
            title: 'Mock Sampling Methods.',
            publicationName: null,
            language: null,
            documentLink: 'https://www.ncbi.nlm.nih.gov/protein/32155099',
            authors: ['Berndt AE'],
            publicationDate: null,
            literatureReview: '29169',
            searchEvent: '29270',
            csv: '',
          },
          {
            id: '29172',
            oid: null,
            urn: '29938444',
            title: 'Mock Sample Size Determination In Health Research.',
            publicationName: null,
            language: null,
            documentLink: 'https://www.ncbi.nlm.nih.gov/protein/29938444',
            authors: ['Sami W', 'Alrukban MO', 'Waqas T', 'Asad MR', 'Afzal K'],
            publicationDate: null,
            literatureReview: '29169',
            searchEvent: '29270',
            csv: '',
          },
          {
            id: '29241',
            oid: null,
            urn: '32706550',
            title: 'Randomization versus Real-World Evidence.',
            publicationName: null,
            language: null,
            documentLink: 'https://www.ncbi.nlm.nih.gov/protein/32706550',
            authors: ['Shrier I', 'Stovitz SD'],
            publicationDate: null,
            literatureReview: '29169',
            searchEvent: '29270',
            csv: '',
          },
          {
            id: '29244',
            oid: null,
            urn: '32706551',
            title: 'Randomization versus Real-World Evidence.',
            publicationName: null,
            language: null,
            documentLink: 'https://www.ncbi.nlm.nih.gov/protein/32706551',
            authors: ['Balzer LB', 'Dominici F'],
            publicationDate: null,
            literatureReview: '29169',
            searchEvent: '29270',
            csv: '',
          },
        ],
        literatureReview: '29067',
        searchEvent: '29168',
        uiFlags: 6,
      },
    ],
    includedSearchResults: [],
    createdBy: {
      id: 'user1',
      createdBy: 'AdminUser',
      displayName: 'User 1',
      email: 'user1@example.com',
      isActive: true,
      language: 'en',
      lastModifiedBy: 'AdminUser',
      role: '',
    },
    lastModifiedBy: '6447fab3a4cf88f35a308625',
    createdDate: '2023-07-24T15:28:04.157Z',
    lastModifiedDate: '2023-08-03T16:54:23.280Z',
    uiFlags: 7,
  },
]

export default literatureReviewMock
