import { Select } from 'antd'
import { t } from 'i18next'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useUsers } from '../hooks'
import { User } from '../store/reducers/userReducer'

interface SelectOption {
  key: string
  value: string
  label: string
  disabled?: boolean
}

interface SelectParticpantProps {
  onChange?: (value: string[] | undefined) => void
  disabled?: boolean
  initialParticipants?: string[]
  allParticipants?: User[]
  disableRemove?: boolean
}

const SelectParticipant = memo(
  ({
    onChange,
    disabled,
    initialParticipants,
    allParticipants,
    disableRemove,
  }: SelectParticpantProps) => {
    const { userList: allUsers, loading } = useUsers({})
    const [participants, setParticipants] = useState<string[]>(
      initialParticipants || []
    )

    const options: SelectOption[] = useMemo(() => {
      const users =
        allParticipants !== undefined && allParticipants.length !== 0
          ? allParticipants
          : allUsers
      return (users ?? []).map((user: User) => ({
        key: user.id,
        value: user.id,
        label: user.displayName,
        ...(disableRemove && {
          disabled: initialParticipants?.includes(user.id),
        }),
      }))
    }, [allUsers, allParticipants, disableRemove, initialParticipants])

    const participantLabels = useMemo(() => {
      return participants.map(id => {
        const option = options.find(opt => opt.value === id)
        return option ? { value: id, label: option.label } : null
      }).filter(Boolean)
    }, [participants, options])

    const handleChange = useCallback(
      (value: any[]) => {
        const ids = value.map(v => v.value)
        setParticipants(ids)
        if (onChange) {
          onChange(ids)
        }
      },
      [onChange]
    )

    useEffect(() => {
      setParticipants(initialParticipants || [])
    }, [initialParticipants])

    return (
      <Select
        mode="multiple"
        size="large"
        style={{ width: '100%' }}
        options={options}
        disabled={disabled}
        value={participantLabels}
        labelInValue
        loading={loading}
        onChange={handleChange}
        optionFilterProp="label"
        notFoundContent={loading ? t('loading') : t('noUsersFound')}
      />
    )
  }
)

export default SelectParticipant
