import { RequiredMark } from 'antd/lib/form/Form'
import { SourceType } from '../store/reducers/dataSourceReducer'
import {
  BiomarkConfigValues,
  WorkspaceTypeCode,
} from '../store/reducers/workspaceReducer'
import {
  AlgorithmRunStatus,
  BackEndSortOrder,
  DataType,
  MissingReferenceCategory,
  PrivacyType,
  SearchMethod,
  VisibilityType,
} from '../types/generalTypes'
import {
  permissionActions,
  permissionNames,
  permissionValues,
} from './permissionConstant'
import { urlList } from './urlConstant'
export type {
  RolePermissionAction as PermissionAction,
  RolePermissionName as PermissionName,
  RolePermissionType as PermissionType,
} from './permissionConstant'

export {
  permissionActions,
  permissionNames,
  permissionValues as permissionTypes,
  urlList,
}

export type ConstantDictionary = { [constant: string]: string }

export type SearchMethodDictionary = { [searchMethod: string]: SearchMethod }
export const searchMethods: SearchMethodDictionary = {
  SEMANTIC_SEARCH_METHOD: 'SEMANTIC_SEARCH_METHOD',
  KEYWORD_SEARCH_METHOD: 'KEYWORD_SEARCH_METHOD',
}

export type DataTypeDictionary = { [dataType: string]: DataType }
export const dataTypes: DataTypeDictionary = {
  FRAMEWORK: 'Framework',
  FRAMEWORK_NODE: 'Framework Node',
  DOCUMENT: 'Document',
}

export const dateFormat = {
  PRIMARY: 'MMM DD YYYY HH:mm:ss',
}

export const EMAIL_FORMAT_REGEX =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/

export const PASSWORD_SPECIAL_CHARACTER_REGEX =
  /[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]/

export const PASSWORD_MIN_LENGTH = 8
export const EGNYTE_AUTH_CODE_INFORMATION_URL =
  'https://developers.egnyte.com/docs/read/Public_API_Authentication'
export const TENANT_ID_INFORMATION_URL =
  'https://learn.microsoft.com/en-us/sharepoint/find-your-office-365-tenant-id'

export const COMMON_PASSWORD_LIST_LINK =
  'https://www.passwordrandom.com/most-popular-passwords'
export const BREACH_DATABASE_LINK = 'https://haveibeenpwned.com/'

export const EGNYTE_CLIENT_ID = 'un4dv4xkveyura9vju6rzsfh'
export const SHAREPOINT_CLIENT_ID = '38068272-c0a5-4fbb-8ab2-fc2fabeb1669'

export const EGNYTE_SOURCE_TYPE: SourceType = '1'
export const SHAREPOINT_SOURCE_TYPE: SourceType = '2'
export const PUBMED_SOURCE_TYPE: SourceType = '3'

export const EGNYTE_TYPECODE = 'EGN'
export const REGDOCS_TYPECODE = 'REGDOCS'
export const SHAREPOINT_TYPECODE = 'SP'
export const AWS_S3_BUCKET_TYPECODE = 'S3'
export const PUBMED_TYPECODE = 'NLM'

export const ASSETS_TYPE = 'ASSETS'
export const CLINICAL_TYPE = 'CLINICAL'

export const BACK_END_PULL_INTERVAL = 10000

export type DataSourcePrivacyType = {
  ORGANIZATION: PrivacyType
  PRIVATE: PrivacyType
  FEDERATION: PrivacyType
  PUBLIC: PrivacyType
}

export const privacyTypes: DataSourcePrivacyType = {
  ORGANIZATION: 'ORGANIZATION',
  PRIVATE: 'PRIVATE',
  FEDERATION: 'FEDERATION',
  PUBLIC: 'PUBLIC',
}

export type VisibilityTypeDictionary = { [tabKey: string]: VisibilityType }
export const visibilityTypes: VisibilityTypeDictionary = {
  EGNYTE: 'EGNYTE',
  SHAREPOINT: 'SHAREPOINT',
}

export const NO_DATA_PLACEHOLDER = '—'

interface HttpErrorStatus {
  ERROR_400_BAD_REQUEST: number
  ERROR_401_UNAUTHORIZED: number
  ERROR_403_FORBIDDEN: number
  ERROR_404_NOT_FOUND: number
  ERROR_500_INTERNAL_SERVER_ERROR: number
  ERROR_502_BAD_GATEWAY: number
  ERROR_503_SERVICE_UNAVAILABLE: number
}
export const httpErrorStatus: HttpErrorStatus = {
  ERROR_400_BAD_REQUEST: 400,
  ERROR_401_UNAUTHORIZED: 401,
  ERROR_403_FORBIDDEN: 403,
  ERROR_404_NOT_FOUND: 404,
  ERROR_500_INTERNAL_SERVER_ERROR: 500,
  ERROR_502_BAD_GATEWAY: 502,
  ERROR_503_SERVICE_UNAVAILABLE: 503,
}

interface PageType {
  WORKSPACE: string
  SEARCH: string
  SEARCH_REVIEW: string
  DATA_SOURCE: string
  DATA_STORE: string
  USER: string
  OFR: string
  OTHER: string
}
export const pageTypes: PageType = {
  WORKSPACE: 'WORKSPACE',
  SEARCH: 'SEARCH',
  SEARCH_REVIEW: 'SEARCH_REVIEW',
  DATA_SOURCE: 'DATA_SOURCE',
  DATA_STORE: 'DATA_STORE',
  USER: 'USER',
  OFR: 'OFR',
  OTHER: 'OTHER',
}

export type WorkspaceTypeCodes = {
  BIOMARK: WorkspaceTypeCode
  OFR_QR: WorkspaceTypeCode
  REFAI: WorkspaceTypeCode
  DEL: WorkspaceTypeCode
  REGSUB: WorkspaceTypeCode
  RISK_REL: WorkspaceTypeCode
}

export const workspaceTypes: WorkspaceTypeCodes = {
  BIOMARK: 'BIOMARK',
  OFR_QR: 'OFR_QR',
  REFAI: 'REFAI',
  DEL: 'DEL',
  REGSUB: 'REGSUB',
  RISK_REL: 'RISK_REL',
}

export type AlgorithmRunStatuses = {
  ONGOING_AI: AlgorithmRunStatus
  ONGOING_INGESTION: AlgorithmRunStatus
  COMPLETE: AlgorithmRunStatus
  FAILED: AlgorithmRunStatus
  CANCELLED: AlgorithmRunStatus
  READY: AlgorithmRunStatus
}

export const algorithmRunStatuses: AlgorithmRunStatuses = {
  ONGOING_AI: 'ONGOING_AI',
  ONGOING_INGESTION: 'ONGOING_INGESTION',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  READY: 'READY',
}

export type MissingReferenceCategories = {
  IRRELEVANT_REFERENCES: MissingReferenceCategory
  EXTERNAL_REFERENCES: MissingReferenceCategory
  INTERNAL_REFERENCES: MissingReferenceCategory
}

export const missingReferenceCategories: MissingReferenceCategories = {
  IRRELEVANT_REFERENCES: 'Irrelevant references',
  EXTERNAL_REFERENCES: 'External references',
  INTERNAL_REFERENCES: 'Internal references',
}

export type ModalSteps = {
  title: string
}

export const optionalRequiredMark: RequiredMark = 'optional'

export const NOTIFICATION_DEFAULT_DURATION = 3

export type BiomarkModelType = 'linear' | 'quad' | 'cube' | 'radial'

export enum BiomarkModelTypeEnum {
  LINEAR = 'linear',
  QUAD = 'quad',
  CUBE = 'cube',
  RADIAL = 'radial',
}

export const biomarkFormRanges = {
  maxScore: {
    min: 0.0,
    max: 1,
    default: 1,
  },
  trials: {
    min: 0,
    max: 1000,
    default: 32,
  },
  testPercent: {
    min: 5,
    max: 95,
    default: 20,
  },
  generations: {
    min: 1,
    max: 1000,
    default: 32,
  },
  population: {
    min: 2,
    max: 5000,
    default: 100,
  },
  mutationRate: {
    min: 0.01,
    max: 0.99,
    default: 0.2,
  },
  numMutants: {
    min: -1,
    max: 5000,
    default: -1,
  },
  numXovers: {
    min: -1,
    max: 5000,
    default: 0,
  },
  numRefines: {
    min: -1,
    max: 5000,
    default: -1,
  },
  modelType: {
    types: ['linear', 'quadratic', 'cubic', 'radial'],
    default: BiomarkModelTypeEnum.LINEAR,
  },
}

export const defaultBiomarkConfig: BiomarkConfigValues = {
  maxScore: biomarkFormRanges.maxScore.default,
  trials: biomarkFormRanges.trials.default,
  testPercent: biomarkFormRanges.testPercent.default / 100,
  generations: biomarkFormRanges.generations.default,
  population: biomarkFormRanges.population.default,
  mutationRate: biomarkFormRanges.mutationRate.default,
  numMutants: biomarkFormRanges.numMutants.default,
  numXovers: biomarkFormRanges.numXovers.default,
  numRefines: biomarkFormRanges.numRefines.default,
  modelType: biomarkFormRanges.modelType.default,
}

export interface BackEndSortOrders {
  ASC: BackEndSortOrder
  DESC: BackEndSortOrder
}

export const backEndSortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const UIFLAGS = {
  CAN_CREATE: 1 << 0,
  CAN_UPDATE: 1 << 1,
  CAN_DELETE: 1 << 2,
  CAN_ACTION_1: 1 << 3,
  CAN_ACTION_2: 1 << 4,
  CAN_ACTION_3: 1 << 5,
  CAN_ACTION_4: 1 << 6,
}

export const delConfigurationModalTypes = {
  DIN: 'DIN',
  TABLE_A: 'TableA',
}

export type OfrBlockType =
  | 'none'
  | 'checkbox'
  | 'expectedData'
  | 'ignored'
  | 'pagination'
  | 'projectNumber'
  | 'signature'
  | 'filled'

export interface OfrBlockTypes {
  NONE: OfrBlockType
  CHECKBOX: OfrBlockType
  EXPECTED_DATA: OfrBlockType
  IGNORED: OfrBlockType
  PAGINATION: OfrBlockType
  PROJECT_NUMBER: OfrBlockType
  SIGNATURE: OfrBlockType
  FILLED: OfrBlockType
}

export const ofrBlockTypes: OfrBlockTypes = {
  NONE: 'none',
  CHECKBOX: 'checkbox',
  EXPECTED_DATA: 'expectedData',
  IGNORED: 'ignored',
  PAGINATION: 'pagination',
  PROJECT_NUMBER: 'projectNumber',
  SIGNATURE: 'signature',
  FILLED: 'filled',
}

export const ofrBlockTypeNames = {
  none: 'None',
  checkbox: 'Checkbox',
  expectedData: 'Expected Data',
  ignored: 'Ignored',
  pagination: 'Pagination',
  projectNumber: 'Project Number',
  signature: 'Signature',
  filled: 'Filled',
}

type OfrDocumentStatus = 'PROCESSING' | 'READY' | 'INGESTION' | 'FAILED'

export interface OfrDocumentStatuses {
  INGESTION: OfrDocumentStatus
  READY: OfrDocumentStatus
  PROCESSING: OfrDocumentStatus
  FAILED: OfrDocumentStatus
}

export const ofrDocumentStatus: OfrDocumentStatuses = {
  INGESTION: 'INGESTION',
  READY: 'READY',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
}

export type QualityReviewDeviationType =
  | 'missingInfo'
  | 'missingCheck'
  | 'overChecked'
  | 'badSignature'
  | 'missingPage'
  | 'mismatchingPage'
  | 'floatingText'

export interface QualityReviewDeviations {
  MISSING_INFO: QualityReviewDeviationType
  MISSING_CHECK: QualityReviewDeviationType
  OVER_CHECKED: QualityReviewDeviationType
  BAD_SIGNATURE: QualityReviewDeviationType
  MISSING_PAGE: QualityReviewDeviationType
  MISMATCHING_PAGE: QualityReviewDeviationType
  FLOATING_TEXT: QualityReviewDeviationType
}

export const qualityReviewDeviations: QualityReviewDeviations = {
  MISSING_INFO: 'missingInfo',
  MISSING_CHECK: 'missingCheck',
  OVER_CHECKED: 'overChecked',
  BAD_SIGNATURE: 'badSignature',
  MISSING_PAGE: 'missingPage',
  MISMATCHING_PAGE: 'mismatchingPage',
  FLOATING_TEXT: 'floatingText',
}

export const qualityReviewDeviationNames = {
  missingInfo: 'Missing Information',
  missingCheck: 'Missing Check',
  overChecked: 'Over Checked',
  badSignature: 'Bad Signature',
  missingPage: 'Missing Page',
  mismatchingPage: 'Mismatching Page',
  floatingText: 'Floating Text',
}

export type SearcheReviewSearchQueryResultItemStatus =
  | 'INGESTION'
  | 'READY'
  | 'FAILED'
  | 'PROCESSING'

export interface SearchReviewSearchQueryResultItemStatuses {
  INGESTION: SearcheReviewSearchQueryResultItemStatus
  READY: SearcheReviewSearchQueryResultItemStatus
  FAILED: SearcheReviewSearchQueryResultItemStatus
  PROCESSING: SearcheReviewSearchQueryResultItemStatus
}

export const searchReviewSearchQueryResultItemStatus: SearchReviewSearchQueryResultItemStatuses =
  {
    INGESTION: 'INGESTION',
    READY: 'READY',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
  }

export enum BadgeColors {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  PROCESSING = 'processing',
  WARNING = 'warning',
  DATA_SOURCE = 'cyan',
  ROOT_CAUSE_DISCOVERY = 'gold',
  FILTERS = 'magenta',
  SEARCH_REVIEWS = 'purple',
  TEMPLATE_ANNOTATIONS = 'volcano',
  STATUS_OPENED = 'lime',
  STATUS_CLOSED = 'grey',
}

export const searchReviewSearchQueryResultItemStatusColorKeys = {
  [searchReviewSearchQueryResultItemStatus.PROCESSING]: BadgeColors.PROCESSING,
  [searchReviewSearchQueryResultItemStatus.READY]: BadgeColors.SUCCESS,
  [searchReviewSearchQueryResultItemStatus.FAILED]: BadgeColors.ERROR,
  [searchReviewSearchQueryResultItemStatus.INGESTION]: BadgeColors.PROCESSING,
}

export const searchReviewSearchQueryResultItemStatusTextMap = {
  [searchReviewSearchQueryResultItemStatus.PROCESSING]: 'Processing',
  [searchReviewSearchQueryResultItemStatus.READY]: 'Summarized',
  [searchReviewSearchQueryResultItemStatus.FAILED]: 'Failed',
  [searchReviewSearchQueryResultItemStatus.INGESTION]: 'Ingestion',
}

export type SearchReviewSavingType = 'NEW' | 'EXISTING'

export type SearchReviewSavingTypeDictionary = {
  [tabKey: string]: SearchReviewSavingType
}

export type SearchReviewType = 'HISTORICAL_NC_REVIEW' | 'LITERATURE_REVIEW'

export type SearchReviewTypeDictionary = {
  [tabKey: string]: SearchReviewType
}

export const searchReviewTypes: SearchReviewTypeDictionary = {
  HISTORICAL_NC_REVIEW: 'HISTORICAL_NC_REVIEW',
  LITERATURE_REVIEW: 'LITERATURE_REVIEW',
}

export const searchReviewSavingTypes: SearchReviewSavingTypeDictionary = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
}

export type RiskReliefSmartFunctionType =
  | 'DeviationClassification'
  | 'RootCauseDiscovery'
  | 'CapaGeneration'

export interface RiskReliefSmartFunctionTypes {
  DEVIATION_CLASSIFICATION: RiskReliefSmartFunctionType
  ROOT_CAUSE_DISCOVERY: RiskReliefSmartFunctionType
  CAPA_GENERATION: RiskReliefSmartFunctionType
}

export const riskReliefSmartFunctionTypes: RiskReliefSmartFunctionTypes = {
  DEVIATION_CLASSIFICATION: 'DeviationClassification',
  ROOT_CAUSE_DISCOVERY: 'RootCauseDiscovery',
  CAPA_GENERATION: 'CapaGeneration',
}

export type RiskReliefDeviationStatus = 'opened' | 'closed' | 'all'

export interface RiskReliefDeviationStatuses {
  ALL: RiskReliefDeviationStatus
  OPENED: RiskReliefDeviationStatus
  CLOSED: RiskReliefDeviationStatus
}
export const riskReliefDeviationStatuses: RiskReliefDeviationStatuses = {
  ALL: 'all',
  OPENED: 'opened',
  CLOSED: 'closed',
}
