import { styled } from 'styled-components'

const ModalTextDescription = styled.div`
  font-size: 14px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`

export default ModalTextDescription
