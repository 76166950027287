import { Form, FormInstance, Select } from 'antd'
import { t } from 'i18next'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import workspaceService from '../../../../services/workspaceService'
import { RootState } from '../../../../store'
import {
  WorkspaceType,
  setWorkspaceTypeList,
} from '../../../../store/reducers/workspaceReducer'

interface CreateWorkspaceModalTypeStepProps {
  form: FormInstance
}

const CreateWorkspaceModalTypeStep = memo(
  ({ form }: CreateWorkspaceModalTypeStepProps) => {
    const dispatch = useDispatch()
    const workspaceTypeList = useSelector(
      (state: RootState) => state.workspace.workspaceTypeList
    )

    useEffect(() => {
      workspaceService.getWorkspaceType().then((response: WorkspaceType[]) => {
        dispatch(setWorkspaceTypeList(response))
      })
    }, [dispatch])

    return (
      <Form.Item
        label={t('createWorkspaceModal.typeStep.subHeader')}
        name="type"
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <Select
          placeholder={t('createWorkspaceModal.typeStep.selectPlaceholder')}
          options={workspaceTypeList?.map((workspaceType: WorkspaceType) => ({
            label: workspaceType.type_name,
            value: workspaceType.id,
          }))}
          onChange={(value, option) => {
            const selectedType = workspaceTypeList?.find(
              (type) => type.id === value
            )
            if (selectedType) {
              form.setFieldsValue({
                workspaceTypeName: selectedType.type_name,
                workspaceTypeCode: selectedType.type_code,
              })
            }
          }}
        />
      </Form.Item>
    )
  }
)

export default CreateWorkspaceModalTypeStep
