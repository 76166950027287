import { FormInstance } from 'antd'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useWorkspaces } from '.'
import { riskReliefDeviationStatuses } from '../constants/constants'
import notificationService from '../services/notificationService'
import workspaceService from '../services/workspaceService'
import { Document, SearchReview } from '../store/reducers/searchReviewReducer'
import { Deviation } from '../store/reducers/workspaceReducer'

interface UseProcessDeviationProps {
  form: FormInstance
  onSuccess?: () => void
}

const useProcessDeviation = ({ form, onSuccess }: UseProcessDeviationProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { refreshWorkspaceList } = useWorkspaces({ preventFetch: true })

  const addDeviationsToWorkspaceWithSearchReviewAndSearchQueryIds = useCallback(
    async (
      searchReviewId: string,
      searchQueryId: string,
      documents: Document[]
    ) => {
      const selectedWorkspace = form.getFieldValue('workspace')

      if (!selectedWorkspace?.value) {
        notificationService.notificationError(
          t('processDeviationModal.missingData')
        )
        return
      }

      try {
        setConfirmLoading(true)
        const currentWorkspace = await workspaceService.getWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
        })

        const existingConfig = currentWorkspace.config?.riskReliefConfig || {
          searchReviewIds: [],
          deviations: [],
        }

        if (!existingConfig.searchReviewIds.includes(searchReviewId)) {
          existingConfig.searchReviewIds.push(searchReviewId)
        }

        const newDeviations = documents.map((doc) => ({
          searchReviewId,
          searchQueryId,
          searchResultId: doc.id,
          open: true,
          deviationStatus: riskReliefDeviationStatuses.OPENED,
        }))

        // filter duplicates
        const uniqueDeviations = newDeviations.filter(
          (newDev) =>
            !existingConfig.deviations.some(
              (existingDev) =>
                existingDev.searchReviewId === newDev.searchReviewId &&
                existingDev.searchQueryId === newDev.searchQueryId &&
                existingDev.searchResultId === newDev.searchResultId
            )
        )

        if (uniqueDeviations.length === 0) {
          notificationService.notificationWarning(
            t('processDeviationModal.allDocumentsExist')
          )
          return
        }

        // Update workspace with new config
        await workspaceService.updateWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
          config: {
            ...currentWorkspace.config,
            riskReliefConfig: {
              searchReviewIds: existingConfig.searchReviewIds,
              deviations: [...existingConfig.deviations, ...uniqueDeviations],
            },
          },
        })

        const skippedCount = documents.length - uniqueDeviations.length
        if (skippedCount > 0) {
          notificationService.notificationSuccess(
            t('processDeviationModal.partialSuccess', {
              added: uniqueDeviations.length,
              skipped: skippedCount,
            })
          )
        } else {
          notificationService.notificationSuccess(
            t('processDeviationModal.submitSuccess')
          )
        }

        await refreshWorkspaceList()
        onSuccess?.()
      } catch (error) {
        console.error('Failed to update workspace:', error)
        notificationService.notificationError(
          t('processDeviationModal.submitError')
        )
      } finally {
        setConfirmLoading(false)
      }
    },
    [form, onSuccess, refreshWorkspaceList]
  )

  // old Version will delete later
  const addDeviationsToWorkspace = useCallback(
    async (documents: Document[], searchReview?: SearchReview) => {
      const selectedWorkspace = form.getFieldValue('workspace')

      if (!selectedWorkspace?.value || !documents.length) {
        notificationService.notificationError(
          t('processDeviationModal.missingData')
        )
        return
      }

      try {
        setConfirmLoading(true)
        const [currentWorkspace] = await Promise.all([
          workspaceService.getWorkspaceDetails({
            workspaceId: selectedWorkspace.value,
          }),
        ])

        const existingDeviations =
          currentWorkspace.config?.riskReliefDeviations || []

        // filter documents that exist
        const newDocuments = documents.filter(
          (doc) =>
            !existingDeviations.some(
              (existing) =>
                existing.id === doc.id &&
                (typeof existing.searchReview === 'string'
                  ? existing.searchReview === searchReview?.id
                  : existing.searchReview?.id === searchReview?.id)
            )
        )

        if (newDocuments.length === 0) {
          notificationService.notificationWarning(
            t('processDeviationModal.allDocumentsExist')
          )
          return
        }

        const deviationsToAdd: Deviation[] = newDocuments.map((document) => ({
          ...document,
          searchReview: searchReview,
          deviationStatus: riskReliefDeviationStatuses.OPENED,
        }))

        await workspaceService.updateWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
          config: {
            ...currentWorkspace.config,
            riskReliefDeviations: [...existingDeviations, ...deviationsToAdd],
          },
        })

        const skippedCount = documents.length - newDocuments.length
        if (skippedCount > 0) {
          notificationService.notificationSuccess(
            t('processDeviationModal.partialSuccess', {
              added: newDocuments.length,
              skipped: skippedCount,
            })
          )
        } else {
          notificationService.notificationSuccess(
            t('processDeviationModal.submitSuccess')
          )
        }

        await refreshWorkspaceList()
        onSuccess?.()
      } catch (error) {
        console.error('Failed to update workspace:', error)
        notificationService.notificationError(
          t('processDeviationModal.submitError')
        )
      } finally {
        setConfirmLoading(false)
      }
    },
    [form, refreshWorkspaceList, onSuccess]
  )

  return {
    confirmLoading,
    addDeviationsToWorkspace,
    addDeviationsToWorkspaceWithSearchReviewAndSearchQueryIds,
  }
}

export default useProcessDeviation
