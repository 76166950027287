import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userService } from '../services'
import { RootState } from '../store'
import { User, UserDetails, setUsers } from '../store/reducers/userReducer'
import { HttpResponse } from '../types/generalTypes'
import useDownloadFile from './useDownloadFile'

export interface UserResponse extends HttpResponse {
  data: User[]
}

interface UseUsersProps {
  userId?: string
  preventFetch?: boolean
}

const useUsers = ({ userId, preventFetch = false }: UseUsersProps) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const downloadCsv = useDownloadFile()
  const userList: User[] | undefined = useSelector(
    (state: RootState) => state.user.users
  )
  const selectedUser: UserDetails | undefined = useSelector(
    (state: RootState) => state.user.selected
  )

  const fetchUserList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        userService
          .getUsers()
          .then((response: UserResponse) => {
            setLoading(false)
            dispatch(setUsers(response.data))
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios fetch error', error)
            setLoading(false)
          })
      }
    },
    [dispatch, preventFetch, loading]
  )

  const refreshUserList = useCallback(async () => {
    fetchUserList(true)
  }, [fetchUserList])

  useEffect(() => {
    refreshUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportUserListCsv = useCallback(() => {
    if (userList) {
      userService
        .exportUserList()
        .then((response) => {
          const fileName = 'users.csv'
          downloadCsv(response, fileName)
        })
        .catch((error: any) => {
          console.error('Error fetching user list CSV data', error)
        })
    }
  }, [userList, downloadCsv])

  const exportUserDetailsCsv = useCallback(
    (userId: string) => {
      if (!selectedUser || userId === undefined || userId === '') return

      userService
        .exportUserDetails({ userId })
        .then((response) => {
          const fileName = `USER_${selectedUser?.displayName}_${selectedUser?.email}.csv`
          downloadCsv(response, fileName)
        })
        .catch((error: any) => {
          console.error('Error fetching user details CSV data', error)
        })
    },
    [downloadCsv, selectedUser]
  )

  const findUser = useCallback(
    (userId: string) => userList?.find((user) => user.id === userId),
    [userList]
  )

  return {
    refreshUserList,
    findUser,
    userList,
    selectedUser,
    loading,
    error,
    exportUserListCsv,
    exportUserDetailsCsv,
  }
}

export default useUsers
