import { Select, theme } from 'antd'
import { t } from 'i18next'
import { useEffect, useState, useMemo } from 'react'
import { SelectOption } from '../../../types/generalTypes'
import { useParams } from 'react-router-dom'
import { useUsers, useWorkspaceDetails } from '../../../hooks'

const { useToken } = theme

interface SelectDeviationOwnerProps {
  defaultValue?: string
  value?: string
  onChange?: (value: string | undefined) => void
  loading?: boolean
}

const SelectDeviationOwner = ({
  defaultValue,
  value,
  onChange,
  loading,
}: SelectDeviationOwnerProps) => {
  const { workspaceId } = useParams()
  const { token } = useToken()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue
  )
  const { findUser } = useUsers({})

  const options: SelectOption[] = useMemo(
    () => [
      {
        label: t('workspaceDetailsPage.selectDeviationOwner.all') || '',
        value: 'all',
      },
      ...(selectedWorkspace?.config?.riskReliefDeviations?.map((deviation) => ({
        label: findUser(deviation?.savedBy ?? '')?.displayName ?? '',
        value: deviation?.savedBy ?? '',
      })) ?? []),
    ],
    [selectedWorkspace, findUser]
  )

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  const handleChange = (value: string) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      style={{ minWidth: 200, color: token.colorPrimary }}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('workspaceDetailsPage.selectDeviationOwner.all')}
      loading={loading}
    />
  )
}

export default SelectDeviationOwner
