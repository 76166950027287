import Table, { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import moment from 'moment'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  InitialMessageContainer,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  privacyTypes,
} from '../../constants/constants'
import { useDataStores } from '../../hooks'
import dataStoreService from '../../services/dataStoresService'
import notificationService from '../../services/notificationService'
import {
  DataStore,
  setSelectedDataStore,
} from '../../store/reducers/dataStoreReducer'
import { routePaths } from '../RootPage'
import CreateDataStoreModal from './modals/CreateDataStoreModal'

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0;
`

interface DataStoreExtended extends DataStore {
  key: string
  destination: string
}

const DataStoresListPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dataStoreList, refreshDataStoreList, loading, exportDataStoreList } =
    useDataStores({})
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const formattedData: DataStoreExtended[] =
    useMemo(
      () =>
        dataStoreList?.map((dataStore: DataStore) => ({
          ...dataStore,
          key: dataStore.id,
          destination: dataStore.typeName,
        })),
      [dataStoreList]
    ) || []

  const handleCancelDelete = (event: MouseEvent) => {
    event.stopPropagation()
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = (event: MouseEvent) => {
    event.stopPropagation()
    if (deleteId) {
      setDeleteLoading(true)
      dataStoreService
        .deleteDataStore({ dataStoreId: deleteId, navigate })
        .then(() => {
          notificationService.notificationSuccess(
            t('dataStoresListPage.deleteSuccessful')
          )
          refreshDataStoreList()
          setDeleteLoading(false)
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          setDeleteLoading(false)
        })
    }
    setConfirmDeleteModalOpen(false)
    setDeleteId(undefined)
  }

  const handleClick = (id: string) => {
    const dataStore = dataStoreList.find(
      (dataStore: DataStore) => dataStore.id === id
    )
    if (dataStore) {
      dispatch(setSelectedDataStore(dataStore))
    }
    navigate(`${routePaths.DATA_STORE_DETAILS}/${id}`)
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setDeleteId(id)
    setConfirmDeleteModalOpen(true)
  }, [])

  const columns: ColumnsType<DataStoreExtended> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      sorter: (a: DataStoreExtended, b: DataStoreExtended) =>
        a.title && b.title ? a.title.localeCompare(b.title) : -1,
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Destination'),
      dataIndex: 'destination',
      key: 'destination',
      sorter: (a: DataStoreExtended, b: DataStoreExtended) =>
        a.destination && b.destination
          ? a.destination.localeCompare(b.destination)
          : -1,
      render: (text) => text || NO_DATA_PLACEHOLDER,
    },
    {
      title: t('Creation Date'),
      key: 'createDate',
      dataIndex: 'createdDate',
      sorter: (a: DataStoreExtended, b: DataStoreExtended) =>
        a.createdDate && b.createdDate
          ? moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf()
          : -1,
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id, visibility }) => (
        <>
          {visibility !== privacyTypes.PUBLIC && (
            <DeleteIconButton
              tooltipTitle={t('dataStoresListPage.deleteDataStoreTooltip')}
              onClick={(event: MouseEvent) => handleDeleteClick(event, id)}
            />
          )}
        </>
      ),
    },
  ]

  const handleExportDataStoreList = () => {
    exportDataStoreList()
  }

  return loading ? (
    <SectionLoadingState />
  ) : (
    <>
      <ListPageLayout data-testid="data-stores-list">
        <ListPageHeader
          title={t('dataStoresListPage.title', {
            count: dataStoreList.length,
          })}
          actions={
            <>
              <CreateDataStoreModal />
              <PageContentDropdown
                onExport={handleExportDataStoreList}
                disabled={formattedData.length === 0}
              />
            </>
          }
        />
        {dataStoreList.length === 0 ? (
          <FullPageContainer>
            <InitialMessageContainer>
              {t('dataStoresListPage.initialMessage')}
            </InitialMessageContainer>
          </FullPageContainer>
        ) : (
          <Table
            data-testid="data-stores-list-table"
            rowClassName="page-list-table-row clickable"
            columns={columns}
            dataSource={formattedData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 170px)' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleClick(record.id)
                }, // click row
              }
            }}
          />
        )}
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          loading={deleteLoading}
          confirmButtonLabel={
            t('dataStoresListPage.confirmDeleteModal.yesButton') || ''
          }
        >
          {t('dataStoresListPage.confirmDeleteModal.content')}
        </ConfirmDeleteModal>
      </ListPageLayout>
    </>
  )
}

export default DataStoresListPage
